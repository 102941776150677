var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"450px"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[(_vm.model)?_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{attrs:{"rounded":""}},[_c('v-card-title',{staticClass:"primary--text"},[_vm._v(" Change Password "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.model = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"px-8 pb-8"},[_c('v-text-field',{attrs:{"id":"password","name":"password","label":"Current password","required":"","persistent-hint":"","prepend-icon":"mdi-lock-open","rules":[
            function (val) { return !!val || 'Password is required'; },
            function (val) { return String(val).length >= 6 || 'Minimum 6 characters required'; }
          ],"type":_vm.showPassword.old_password ? '' : 'password',"append-outer-icon":_vm.showPassword.old_password ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append-outer":function($event){_vm.showPassword.old_password = !_vm.showPassword.old_password}},model:{value:(_vm.old_password),callback:function ($$v) {_vm.old_password=$$v},expression:"old_password"}}),_c('v-text-field',{attrs:{"label":"New password","required":"","persistent-hint":"","prepend-icon":"mdi-lock","rules":[
            function (val) { return !!val || 'Password is required'; },
            function (val) { return String(val).length >= 8 ||
              'Password must be 8 characters or longer'; }
          ],"type":_vm.showPassword.new_password ? '' : 'password',"append-outer-icon":_vm.showPassword.new_password ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append-outer":function($event){_vm.showPassword.new_password = !_vm.showPassword.new_password}},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}}),_c('v-text-field',{attrs:{"label":"Confirm password","required":"","persistent-hint":"","prepend-icon":"mdi-lock-check","rules":[
            function (val) { return !!val || 'Password is required'; },
            function (val) { return String(val).length >= 8 ||
              'Password must be 8 characters or longer'; },
            function (val) { return String(val) === String(_vm.new_password) ||
              'Confirm password not matching new password'; }
          ],"type":_vm.showPassword.confirm_password ? '' : 'password',"append-outer-icon":_vm.showPassword.confirm_password ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append-outer":function($event){_vm.showPassword.confirm_password = !_vm.showPassword.confirm_password}},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"primary"},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"type":"submit","disabled":!_vm.valid,"loading":_vm.loading,"rounded":"","text":""}},[_vm._v("Submit")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }